var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topicList-box"},[(_vm.noTopicList)?_c('div',{staticClass:"topicList"},[_c('div',{staticClass:"title"},[_vm._v("题目列表")]),_c('div',{staticClass:"topic-item"},_vm._l((_vm.topicParam.topicList),function(v,i){return _c('div',{key:i,class:[
          _vm.waitNums.length > 0 && _vm.waitNums.indexOf(v.id + '') === -1
            ? 'item-pitch-wait'
            : _vm.pitchNums.indexOf(v.id + '') === -1
            ? 'item'
            : 'item-pitch',
        ],on:{"click":function($event){return _vm.topicClick(v)}}},[_vm._v(" 第"+_vm._s(i + 1 > 9 ? i + 1 : "0" + (i + 1))+"题 ")])}),0)]):_vm._e(),(_vm.show)?_c('div',{staticClass:"knowledgeTag"},[_c('div',{staticClass:"title"},[_vm._v("知识点标签")]),(_vm.topicParam.knowledgeTag.length < 1)?_c('div',{staticClass:"default"},[_c('el-image',{staticStyle:{"width":"100px","height":"75px"},attrs:{"src":require('@/assets/images/course/none.png')}}),_c('p',[_vm._v("暂无标签")])],1):_c('div',{staticClass:"knowledgeTag-item"},_vm._l((_vm.topicParam.knowledgeTag),function(v,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(v.title || v.label)+" ")])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }