<template>
  <div class="problemListDetails">
    <el-button type="primary" style="margin: 20px 0;" size="small" @click="$router.back()"
        >返 回</el-button
      >
    <div class="box">
      <div class="content-left" v-loading="loading">
        <div class="course-title">
          <div style=" width: 80%;">
            <div class="name" v-html="detailList.title"></div>
            <div class="detailed">
              <el-button type="primary" @click="goExam(1)" :disabled="(detailList.questions_list || []).length === 0">自主练习</el-button>
              <el-button type="primary" @click="goExam(2)" :disabled="(detailList.questions_list || []).length === 0">模拟考试</el-button>
              <span style="margin-left:10px;">题目数：{{detailList.questions.length}}</span>
              <span>收藏数：{{detailList.collect_num}}</span>
            </div>
          </div>
          <div class="edit">
            <div class="feedback" @click="handleCommand('collect',2 , detailList.id,'detailList' , !detailList.is_collect)">
              <i class="el-icon-star-off icon" v-if="!detailList.is_collect"></i>
              <i class="el-icon-star-on icon" v-if="detailList.is_collect" style="color: #f56c6c"></i>
              收藏
            </div>
            <div class="feedback" @click="handleCommand('feedback' , detailList.title , '题单',{id:detailList.id})">
              <i class="el-icon-edit-outline"></i>反馈
            </div>
            <div class="feedback" @click="joinList"  style="min-width:85px;">
              <!-- v-if="(userInfo.id==detailList.user_id)||log_id" -->

              查看答题详情
            </div>
            <div class="feedback" @click="editProblemList" v-if="userInfo.id===detailList.user_id">
              编辑题单
            </div>
          </div>
        </div>
        <!-- 介绍 -->
        <el-tabs type="border-card">
          <el-tab-pane label="题单介绍">
            <div class="brief" v-code-html="detailList.description"></div>
          </el-tab-pane>
          <!-- <el-tab-pane label="查看题目">
            <div class="brief">简介</div>
          </el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="content-right">
        <hot-recommend />
      </div>
    </div>
    <Payment ref="Payment" :order_num="order_num"  :mobile="mobile" :price="price"></Payment>
  </div>
</template>

<script>
import Payment from "@/components/Payment/Payment.vue"
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue"
import { detailList } from "@/api/problemList.js"
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  props:['list_id'],
  components: {
    HotRecommend,
    TopicList,Payment
  },
  data () {
    return {
      order_num:'',
      mobile:'',
      detailList: {
        questions: []
      },
      loading: false,
      log_id: '',
      is_buy:0,
      price:''
    }
  },
  methods: {
    routeUpdate(){
      let query = this.$route.query
      this.is_buy=query.is_buy
      //老师查看学生答题状态
      if (query.log_id) {
        this.log_id = query.log_id
      }
      this.loading = true
      this.getDetailList()
    },
    editProblemList () {
      if(this.userInfo.type+'' === '2'){
        this.$handleRoute({list_id:this.list_id},'teachingQuestListEdit')
      }else{
        this.$store.state.topicType = {
          name: '编辑题单',
          detail: this.detailList
        }
        this.$router.push({ path: "/problemListOperate", query: { id: this.list_id } })
      }
    },
    //查看答题详情
    joinList () {
      if (this.log_id) {
        this.$router.push({ path: '/problemList/questAnswer', query: { id: this.list_id, type: '题单', log_id: this.log_id ,is_log_answer:1,noTime:1} })
        return
      }
      this.$router.push({ path: '/problemList/overDetails', query: { id: this.list_id } })
    },
    getDetailList () {

      detailList({ id: this.list_id }).then(res => {

        res.data.description=res.data.description.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
        this.detailList = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    topicClick () { },
    goExam (noTime) {
      this.$router.push({ path: '/problemList/questAnswer', query: { id: this.list_id, noTime,answer_type:noTime==1?1:0 } })
    }
  },
};
</script>

<style lang="less" scoped>
.problemListDetails {
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 70%;
      .brief {
        padding: 2rem 1rem;
      }
      .course-title {
        margin-bottom: 40px;
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            margin-left: 1rem;
            min-width:60px;
          }
          .el-icon-edit-outline,
          .el-icon-star-off,
          .el-icon-star-on,
          .el-icon-circle-plus-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {

          word-break:break-all;
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
          .operation {
            margin-right: 1.5rem;
          }
          .operation[data-disabled=true]{
            color:#c3c3c3;
            pointer-events:none;
          }
        }
      }
      ::v-deep {
        .el-tabs--border-card {
          border-radius: 10px;
        }
        .el-tabs__item {
          font-size: 16px;
          height: 50px;
          width: 130px;
          line-height: 50px;
          text-align: center;
        }
        .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
          color: #00957e;
        }
        .el-tabs__item:hover {
          color: #00957e;
        }
      }
    }
    .content-right {
      width: 27%;
    }
  }
}
</style>