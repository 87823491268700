<template>
  <!-- // 题目列表和知识点标签 -->
  <div class="topicList-box">
    <div class="topicList" v-if="noTopicList">
      <div class="title">题目列表</div>
      <div class="topic-item">
        <div
          :class="[
            waitNums.length > 0 && waitNums.indexOf(v.id + '') === -1
              ? 'item-pitch-wait'
              : pitchNums.indexOf(v.id + '') === -1
              ? 'item'
              : 'item-pitch',
          ]"
          v-for="(v, i) in topicParam.topicList"
          :key="i"
          @click="topicClick(v)"
        >
          第{{ i + 1 > 9 ? i + 1 : "0" + (i + 1) }}题
        </div>
      </div>
    </div>
    <div class="knowledgeTag" v-if="show">
      <div class="title">知识点标签</div>
      <div v-if="topicParam.knowledgeTag.length < 1" class="default">
        <el-image
          style="width: 100px; height: 75px"
          :src="require('@/assets/images/course/none.png')"
        ></el-image>
        <p>暂无标签</p>
      </div>
      <div class="knowledgeTag-item" v-else>
        <div v-for="(v, i) in topicParam.knowledgeTag" :key="i">
          {{ v.title || v.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    noTopicList: {
      default: () => true,
    },
    pitchNums: {
      default: () => [],
    },
    waitNums: {
      default: () => [],
    },
    show: {
      default: () => true,
    },
    topicParam: {
      topicList: [],
      knowledgeTag: [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {
  },
  methods: {
    setWaitNums(waitNums) {
      this.waitNums = waitNums || [];
    },
    topicClick(v) {
      console.log(v, "题单答题");
      if (v.id) {
        this.$emit("topicClick", "TopicInfo" + v.id);
      } else if (v._list && v._list.length) {
        this.$emit("topicClick", "TopicInfo" + v._list[0].id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.default {
  margin: 1rem 0;
  text-align: center;
  p {
    font-size: 14px;
    color: #828282;
  }
}
// .topicList-box {
  // position: fixed;
  // max-width: 360px;
// }
.topicList {
  // top: 200px;
  // position: fixed;
  // width: 345px;
  box-sizing: border-box;
  // z-index: 99;
}
.knowledgeTag {
  margin-top: 40px;
}
.topicList,
.knowledgeTag {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  font-size: 14px;
  .title {
    font-size: 18px;
  }
  .knowledgeTag-item {
    display: flex;
    flex-wrap: wrap;
    > div {
      // width: 43px;
      background: #00957e;
      border-radius: 4px;
      padding: 4px 10px;
      color: #ffffff;
      margin: 1rem 0.45rem 0;
      text-align: center;
    }
  }
  .topic-item {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    .item {
      cursor: pointer;
      // width: 43px;
      background: #fff;
      border-radius: 15px;
      padding: 4px 8px;
      border: 1px solid #00957e;
      // box-sizing: border-box;
      color: #00957e;
      margin: 1rem 0.45rem 0;
    }
    .item-pitch {
      cursor: pointer;
      // width: 43px;
      background: #00957e;
      border-radius: 15px;
      padding: 4px 9px;
      color: #ffffff;
      margin: 1rem 0.45rem 0;
    }
    .item-pitch-err {
      cursor: pointer;
      // width: 43px;
      background: rgb(255, 59, 59);
      border-radius: 15px;
      padding: 4px 9px;
      color: #ffffff;
      margin: 1rem 0.45rem 0;
    }
    .item-pitch-wait {
      cursor: pointer;
      background: gray;
      border-radius: 15px;
      padding: 4px 9px;
      color: #ffffff;
      margin: 1rem 0.45rem 0;
    }
  }
}
</style>
